import { get } from "lodash";
import createSlug from "../../../lib/createSlug";

const beUrl = "https://bayequityhomeloans.com";

export const mapCommunityCareData = pageData => {
    let result = {};
    result.firestoreId = pageData.firestoreId;
    result.title = pageData.title;
    result.name = pageData.name;
    result.email = pageData.email;
    result.cobrandUrl = pageData.cobrandUrl;
    result.contacts = [];
    result.realtor = pageData.realtor;
    result.blogs = pageData.blogs;
    result.corporateBlogs = pageData.news;
    result.hero = pageData.hero;
    result.html = pageData.html;
    result.messageFrom = pageData.messageFrom;
    result.companyLogo = pageData.companyLogo;
    result.companyPhoto = pageData.companyPhoto;

    if (pageData.loanOfficer) {
        // we have a loan officer, use their information, branch, etc.
        let lo = pageData.loanOfficer;
        let { urlslug, title, id } = lo;
        lo.slug = createSlug(urlslug, lo.slug, title, id);
        let contact = {
            name: lo.profile.name,
            email: lo.profile.email,
            title: lo.profile.jobTitle,
            licenseIds: lo.profile.licenseIds,
            phone: lo.contact.phone,
            mobilePhone: lo.contact.mobilePhone,
            fax: lo.contact.fax,
            photoUrl: lo.gallery.photo,
            applyNow: `${beUrl}${lo.slug}applynow`,
            url: `${beUrl}${lo.slug}`,
        };
        result.contacts.push(contact);

        result.contact = {
            address: getAddress(lo.profile.branch),
            city: lo.profile.branch.city,
            state: lo.profile.branch.state,
            zip: lo.profile.branch.zip,
            applyNow: contact.applyNow,
            url: contact.url,
        };

        // lo-specific module info
        result.contactInfo = {
            name: lo.profile.name,
            address1: lo.profile.branch.address1,
            address2: lo.profile.branch.address2,
            address3: lo.profile.branch.address3,
            city: lo.profile.branch.city,
            state: lo.profile.branch.state,
            zip: lo.profile.branch.zip,
            phone: lo.contact.phone,
            mobilePhone: lo.contact.mobilePhone,
            fax: lo.contact.fax,
            email: lo.profile.email,
        };
        result.socialIcons = {
            facebook: lo.contact.facebook,
            instagram: lo.contact.instagram,
            linkedin: lo.contact.linkedin,
            twitter: lo.contact.twitter,
            youtube: lo.contact.youtube,
        };
        result.blogWord = "my";
        result.templateKey = "loan-officer";
        result.licenseIds = lo.profile.licenseIds;
        result.licenseLink = lo.profile.licenseLink;
        result.jobTitle = lo.profile.jobTitle;
        result.team = null;
        result.profile = lo.profile;
        result.gallery = lo.gallery;
        result.branch = lo.profile.branch;
        result.ownerSlug = lo.slug;
    } else if (pageData.team) {
        // we have a team, use it's information - branch, etc.
        result.isTeam = true;
        let { urlslug, title, id } = pageData.team;
        pageData.team.slug = createSlug(
            urlslug,
            id,
            title,
            pageData.team.profile.name,
        );
        result.contact = {
            address: getAddress(pageData.team.profile.branch),
            city: pageData.team.profile.branch.city,
            state: pageData.team.profile.branch.state,
            zip: pageData.team.profile.branch.zip,
            applyNow: `${beUrl}${pageData.team.slug}applynow`,
            url: `${beUrl}${pageData.team.slug}`,
        };
        result.contacts = [];
        let contact = {
            name: pageData.team.profile.name,
            email: pageData.team.profile.email,
            title: "",
            licenseIds: pageData.team.profile.licenseIds,
            phone: pageData.team.contact.phone,
            mobilePhone: pageData.team.contact.mobilePhone,
            fax: pageData.team.contact.fax,
            photoUrl: pageData.team.profile.logo,
        };
        result.contacts.push(contact);

        // team-specific module info
        result.contactInfo = {
            name: pageData.team.profile.name,
            address1: pageData.team.profile.branch.address1,
            address2: pageData.team.profile.branch.address2,
            address3: pageData.team.profile.branch.address3,
            city: pageData.team.profile.branch.city,
            state: pageData.team.profile.branch.state,
            zip: pageData.team.profile.branch.zip,
            phone: pageData.team.contact.phone,
            mobilePhone: pageData.team.contact.mobilePhone,
            fax: pageData.team.contact.fax,
            email: pageData.team.profile.email,
        };
        result.socialIcons = {
            facebook: pageData.team.contact.facebook,
            instagram: pageData.team.contact.instagram,
            linkedin: pageData.team.contact.linkedin,
            twitter: pageData.team.contact.twitter,
            youtube: pageData.team.contact.youtube,
        };
        result.blogWord = "our";
        result.templateKey = "team";
        result.licenseIds = pageData.team.profile.licenseIds;
        result.jobTitle = "";
        result.team = pageData.team;
        result.profile = pageData.team.profile;
        result.gallery = {};
        result.branch = pageData.team.profile.branch;
        result.ownerSlug = pageData.team.slug;
    } else {
        // new page.
        let lo = pageData.loanOfficer;
        let { urlslug, title, id } = lo;
        lo.slug = createSlug(urlslug, lo.slug, title, id);
        let contact = {
            name: lo.profile.name,
            email: lo.profile.email,
            title: lo.profile.jobTitle,
            licenseIds: lo.profile.licenseIds,
            phone: lo.contact.phone,
            mobilePhone: lo.contact.mobilePhone,
            fax: lo.contact.fax,
            photoUrl: lo.gallery.photo,
            applyNow: `${beUrl}${lo.slug}applynow`,
            url: `${beUrl}${lo.slug}`,
        };
        result.contacts.push(contact);

        result.contact = {
            address: getAddress(lo.profile.branch),
            city: lo.profile.branch.city,
            state: lo.profile.branch.state,
            zip: lo.profile.branch.zip,
            applyNow: contact.applyNow,
            url: contact.url,
        };

        // lo-specific module info
        result.contactInfo = {
            name: lo.profile.name,
            address1: lo.profile.branch.address1,
            address2: lo.profile.branch.address2,
            address3: lo.profile.branch.address3,
            city: lo.profile.branch.city,
            state: lo.profile.branch.state,
            zip: lo.profile.branch.zip,
            phone: lo.contact.phone,
            mobilePhone: lo.contact.mobilePhone,
            fax: lo.contact.fax,
            email: lo.profile.email,
        };
        result.socialIcons = {
            facebook: lo.contact.facebook,
            instagram: lo.contact.instagram,
            linkedin: lo.contact.linkedin,
            twitter: lo.contact.twitter,
            youtube: lo.contact.youtube,
        };
        result.blogWord = "my";
        result.templateKey = "loan-officer";
        result.licenseIds = lo.profile.licenseIds;
        result.jobTitle = lo.profile.jobTitle;
        result.team = null;
        result.profile = lo.profile;
        result.gallery = lo.gallery;
        result.branch = lo.profile.branch;
        result.ownerSlug = lo.slug;
    }

    // shared module info
    result.applyNow = !!result.contact ? result.contact.applyNow : "";
    result.applyNowScript = "";
    result.twitter = "";
    result.photo = "";
    result.review = [];
    result.logIn = pageData.logIn;
    result.slug = pageData.slug;
    result.lenderCreditPercentage = pageData.lenderCreditPercentage;
    result.realtorCommissionPercentage = pageData.realtorCommissionPercentage;
    result.realtorCreditPercentage = pageData.realtorCreditPercentage;

    result.leadEmailInfo = {
        slug: pageData.slug,
        name: pageData.name,
        email: getEmail(pageData),
        loanOfficerName: getLoanOfficerName(pageData),
        // realtorName: pageData.realtor.name ?? "",
        // realtorTitle: pageData.realtor.title ?? "",
        // lenderCreditPercentage: get(
        //     g2hCalculatorModule,
        //     "lenderCreditPercentage",
        //     0.0025,
        // ),
        // realtorCommissionPercentage: get(
        //     g2hCalculatorModule,
        //     "realtorCommissionPercentage",
        //     0.025,
        // ),
        // realtorCreditPercentage: get(
        //     g2hCalculatorModule,
        //     "realtorCreditPercentage",
        //     0.1,
        // ),
        lenderCreditPercentage: pageData.lenderCreditPercentage,
        realtorCommissionPercentage: pageData.realtorCommissionPercentage,
        realtorCreditPercentage: pageData.realtorCreditPercentage,
    };

    result.headBeginScript = pageData.headBeginScript;
    result.headEndScript = pageData.headEndScript;
    result.bodyBeginScript = pageData.bodyBeginScript;
    result.bodyEndScript = pageData.bodyEndScript;

    result.modules = pageData.modules || [];

    return result;
};

export const getLoanOfficerName = data => {
    // If this is a team-specific site, use the team name
    return data.team
        ? data.team.profile.name
        : data.loanOfficer
        ? data.loanOfficer.profile.name
        : "";
};

const getEmail = data => {
    // If this is a team-specific site, use the team email
    return data.team
        ? data.team.profile.email
        : data.loanOfficer
        ? data.loanOfficer.profile.email
        : "";
};

const getAddress = branch => {
    let result =
        branch.address1 +
        (branch.address2 ? `, ${branch.address2}` : "") +
        (branch.address3 ? `, ${branch.address3}` : "");
    return result;
};
