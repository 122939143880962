const sluggo = require("slug");

const sluggify = txt => sluggo(txt, { lower: true });

/**
 * Given a list of possible strings, select
 * the first non empty value, replace collection names,
 * and return a sluggified result
 * @param  {...string} args
 */
const createSlug = (...args) => {
    let notEmpty = args.filter(a => !!a);
    let firstNotEmpty = notEmpty.shift();
    if (!firstNotEmpty) return "";
    let stripped = firstNotEmpty
        .replace(/^\/loan-officer|\/team|\/branch\//i, "")
        .replace(/\//gi, "");
    return `/${sluggify(stripped)}/`;
};

module.exports = createSlug;
