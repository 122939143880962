import React from "react";
import { graphql } from "gatsby";

import CommunityCareDetail from "../components/CommunityCareDetail";
import { mapCommunityCareData } from "../components/CommunityCareDetail/lib/helpers";

const CommunityCareTemplate = ({ data }) => {
    let mappedData = mapCommunityCareData(data.communityCarePage);
    return <CommunityCareDetail {...mappedData} />;
};

export default CommunityCareTemplate;

export const pageQuery = graphql`
    query getCommunityCarePageById($id: String!) {
        communityCarePage(id: { eq: $id }) {
            id
            firestoreId
            headBeginScript {
                name
                src
                async
                html
            }
            headEndScript {
                name
                src
                async
                html
            }
            bodyBeginScript {
                name
                src
                async
                html
            }
            bodyEndScript {
                name
                src
                async
                html
            }
            title
            slug
            companyLogo
            companyPhoto
            loanOfficer {
                slug
                hidden
                applyNow
                gallery {
                    photo
                    image1
                    image2
                    image3
                }
                profile {
                    name
                    email
                    jobTitle
                    licenseIds {
                        license
                        id
                    }
                    licenseLink
                    branch {
                        slug
                        title
                        address1
                        address2
                        address3
                        city
                        state
                        zip
                        lat
                        lon
                        dbaLogoBlack
                        loanOfficers {
                            slug
                            hidden
                            gallery {
                                photo
                            }
                            profile {
                                name
                                email
                                jobTitle
                                licenseIds {
                                    license
                                    id
                                }
                            }
                        }
                        teams {
                            slug
                            title
                            hidden
                            contact {
                                phone
                                instagram
                                linkedin
                                fax
                                mobilePhone
                                twitter
                                youtube
                                facebook
                            }
                            applyNow
                            profile {
                                name
                                email
                                licenseIds {
                                    license
                                    id
                                }
                            }
                        }
                        staff {
                            title
                            name
                            email
                            photo
                            license
                        }
                        managers {
                            title
                            name
                            email
                            photo
                            license
                        }
                    }
                }
                contact {
                    phone
                    mobilePhone
                    fax
                    facebook
                    instagram
                    linkedin
                    twitter
                    youtube
                }
            }
            team {
                slug
                applyNow
                profile {
                    name
                    email
                    logo
                    licenseIds {
                        license
                        id
                    }
                    branch {
                        slug
                        title
                        address1
                        address2
                        address3
                        city
                        state
                        zip
                        lat
                        lon
                        dbaLogoBlack
                    }
                }
                contact {
                    phone
                    mobilePhone
                    fax
                    facebook
                    instagram
                    linkedin
                    twitter
                    youtube
                }
                loanOfficers {
                    slug
                    hidden
                    gallery {
                        photo
                    }
                    profile {
                        name
                        jobTitle
                        email
                        licenseIds {
                            license
                            id
                        }
                    }
                }
                staff {
                    title
                    name
                    email
                    photo
                    license
                }
                managers {
                    title
                    name
                    email
                    photo
                    license
                }
            }
            html
            body
            messageFrom
            modules {
                type
                display
                videos {
                    videoTitle
                    videoCaption
                    videoUrl
                }
                facebookPageUrl
                bookings
                calendar
                useBackgroundImage
                backgroundImage
                backgroundImageCustom
                darkTitleText
                instagramToken
                resourceIntro
                resourceList {
                    resourceFile
                    resourceUrl
                    resourceDescription
                }
                event1 {
                    eventImage
                    eventCustomImage
                    eventName
                    eventDate
                    eventTime
                    eventLocation
                    eventDescription
                    eventUrl
                    eventCta
                }
                event2 {
                    eventImage
                    eventCustomImage
                    eventName
                    eventDate
                    eventTime
                    eventLocation
                    eventDescription
                    eventUrl
                    eventCta
                }
                event3 {
                    eventImage
                    eventCustomImage
                    eventName
                    eventDate
                    eventTime
                    eventLocation
                    eventDescription
                    eventUrl
                    eventCta
                }
                awards {
                    awardDescription
                    awardYear
                    awardImage
                    awardBadge
                }
                social_id
                social_page
                showCalculatorMortgage
                showCalculatorRentVsBuy
                showCalculatorHomeAffordability
                showCalculatorRequiredIncome
                showCalculatorRefinance
                contentImage
                contentTitle
                contentText
                profileTitle
                profileShowCompactView
                profileBody
                contactFormFields {
                    type
                    required
                    customFieldType
                    label
                    bammName
                    options {
                        hasCustomLabel
                        label
                        value
                    }
                }
                contactFormTitle
                contactFormSubtitle
                contactUsText
                contactUsTitle
                contactUsBammUrl
                contactUsLeadEmailCcList
                contactUsConfirmEmailText
                contactUsConfirmEmailFrom
                lenderCreditPercentage
                noAppraisalFeeCredit
                realtorCommissionPercentage
                realtorCreditPercentage
                aboutBEHtml
                aboutBEImage
                careTestimonials {
                    image
                    name
                    text
                }
                aboutCareHtml
                bammUrl
                id
            }
            hero {
                heroBackground
                customPhoto
                photoAlignment
            }
            blogs {
                id
                html
                slug
                title
                author {
                    slug
                    profile {
                        name
                    }
                    gallery {
                        photo
                    }
                }
                date
                featured
                categories
                image
            }
            news {
                id
                html
                slug
                title
                date
                featured
                categories
                image
            }
        }
    }
`;
